import React from "react";
import { useWallet } from "@components/Provide/WalletContext";
import { useIntl } from "react-intl";
import { useTracking } from "@hooks/useTracking";
import { compileLaunchUrl } from "@data/model/casino";
import { useDispatch } from "react-redux";
import { openNavigation } from "@actions/navigation";
import useSWR from "swr";
import { getCmsSessionID, getSessionInfo } from "../../data/model/session";
import GameModal from "../GameModal";
import { useAuth } from "../Provide/AuthContext";
import { useModal } from "../Modal/ModalContext";
import { useDevice } from "../Provide/DeviceContext";
import { localeUrl } from "../../core/url";

import { player_fields } from "../../data/model/account";
import { ValidateProfileValuesNeeded } from "../ProfileDynamicFields/ProfileValidations";

// @todo, move to single function
const ShowGame = (props) => {
  const { isAuthenticated } = useAuth();
  const intl = useIntl();
  const { showModal } = useModal();
  const { isMobile } = useDevice();
  const { track } = useTracking();
  const { currency } = useWallet();
  const dispatch = useDispatch();
  const { data: profileFields } = useSWR(
    isAuthenticated ? "player_fields" : null,
    player_fields
  );
  const { data: userProfile = {} } = useSWR(
    isAuthenticated ? "getSessionInfo" : null,
    getSessionInfo
  );
  //   const { data: playerStats } = useSWR(
  //   isAuthenticated ? '/api/player/stats' : null,
  //   stats,
  // );
  // console.log("testing info 1", fields )

  // console.log("testing user info 2", playerStats )

  const eligibaleGame = (item) => {
    let fieldsNeeded = [];
    if (item && profileFields && userProfile) {
      // Compare the required fields in the context against the currently logged in profile to confirm if all fields are populated.
      fieldsNeeded = ValidateProfileValuesNeeded(
        profileFields,
        item.provider,
        userProfile
      );
    }

    return fieldsNeeded;
  };

  const showGame = async (item) => {
    track("Start game", { game: item });
    const fieldsNeeded = eligibaleGame(item);
    if (fieldsNeeded?.length > 0) {
      dispatch(
        openNavigation("UpdateProfile", {
          params: intl.formatMessage({
            defaultMessage: "To play this game, please update the below fields",
            description: "header",
          }),
          game: item,
          provider: item?.provider,
          url: localeUrl("game", intl.locale, { id: item?.id }),
        })
      );
    } else {
      const result = isAuthenticated ? await getCmsSessionID() : null;

      const url = localeUrl("game", intl.locale, { id: item.id });
      // const url = `https://backend.theclubhousecasino.com${!isAuthenticated ? item?.demo : `/games/${item?.id}/${item?.real[currency].id}`}`;

      const launchUrl = compileLaunchUrl(item, isAuthenticated, currency);
      const response = await fetch(launchUrl, {
        redirect: "follow",
        credentials: "include",
      });
      if (response.redirected && response.url.indexOf("/profile/limits") > -1) {
        dispatch(
          openNavigation("ResponsibleGaming", {
            params: intl.formatMessage({
              defaultMessage:
                "You have a limit on your current wallet. Please alter your limits to continue playing games.",
              description: "header",
            }),
          })
        );
      } else {
        window.history.pushState("", "", url);
        showModal(GameModal, { game: item, url, id: item.id });
        // history.push(localeUrl('game', intl.locale, { id: item.id }))
      }
    }
  };

  return props.children({
    ...{ showGame },
  });
};

export default ShowGame;

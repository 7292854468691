import React, { useMemo } from "react";
import cx from "classnames";
import { useIntl } from "react-intl";
import { useModal } from "../../Modal/ModalContext";
import Link from "../../Link";
import { useAuth } from "../../Provide/AuthContext";
import ShowGame from "../ShowGame";
import { useDispatch } from "react-redux";
import { openNavigation } from "@actions/navigation";
import { useRouter } from "../../Provide/RouterContext";
import { useDevice } from "../../Provide/DeviceContext";
import { useWallet } from "../../Provide/WalletContext";
import jackpoticon from "./jackpot.svg";
import dnw from "./dnw.png";
import Cookies from "universal-cookie";
import { cfImgUrl } from "../../../config";

const GameItem = ({ game, className, style, size = "s3" }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const { isAuthenticated } = useAuth();
  const { thumbnails, real, collections } = game;
  const dispatch = useDispatch();
  const { currency, fractionDigits, subUnitsToUnit } = useWallet();

  const cookies = new Cookies();

  const isDropAndWins = useMemo(() => {
    return collections?.filter((item) => item.includes("dropsandwins")) || [];
  }, [collections]);

  const jackpot = useMemo(() => {
    return real?.[currency]?.jackpot;
  }, [real]);

  return (
    <ShowGame>
      {({ showGame }) => (
        <Link
          trackName="Game"
          trackParams={{ component: "GameItem", game: game }} //  trackParams={{ route: router?.routename, component: 'GameItem'}}
          onClick={
            !isAuthenticated && game?.demo
              ? () => {
                  if (
                    cookies.get("registred") ||
                    cookies.get("registred") === "true"
                  ) {
                    dispatch(
                      openNavigation("Auth", {
                        onSuccess: () => showGame(game),
                      })
                    );
                  } else {
                    dispatch(
                      openNavigation("Registration", {
                        onSuccess: () => showGame(game),
                      })
                    );
                  }
                  // showGame(game);
                  // setCookiesForNav();
                }
              : isAuthenticated
              ? () => showGame(game)
              : () => {
                  if (
                    cookies.get("registred") ||
                    cookies.get("registred") === "true"
                  ) {
                    dispatch(
                      openNavigation("Auth", {
                        onSuccess: () => showGame(game),
                      })
                    );
                  } else {
                    dispatch(
                      openNavigation("Registration", {
                        onSuccess: () => showGame(game),
                      })
                    );
                  }
                  // setCookiesForNav();
                }
          }
          // onClick={() => showGame(game)}
          className={cx(
            "border border-1 border-brand-600  relative block overflow-hidden text-white rounded-lg bg-brand-600 transition-all transform scale-100 group md:hover:scale-105",
            className
          )}
          style={{ ...style, transform: "translate3d(0, 0, 0)" }}
        >
          {game?.collections?.find((user) => user.includes("new")) && (
            <div className="absolute z-10 flex items-end justify-center w-20 h-20 font-bold text-white transform rotate-45 -top-1 -right-1 translate-x-9 -translate-y-9 bg-green tetx-center text-12">
              {intl.formatMessage({
                defaultMessage: "New",
                description: "label",
              })}
            </div>
          )}
          {jackpot && (
            <div className="absolute z-10 flex items-center justify-center h-8 pr-2 font-bold text-center text-white transform translate-y-1 rounded-br-lg -top-1 -left-1 bg-green text-12 bg-brand-600 text-gold ">
              <img src={jackpoticon} className="mx-2" loading="lazy" />
              {intl.formatNumber(jackpot / subUnitsToUnit, {
                style: "currency",
                currency,
                minimumFractionDigits: fractionDigits == 2 ? 0 : fractionDigits,
                maximumFractionDigits: fractionDigits == 2 ? 0 : fractionDigits,
              })}
              {/* <CountUp
              start={0}
              end={jackpot}
              duration={100000}
              useEasing={true}
              formattingFn={formatNumber.bind(this)}
            /> */}
            </div>
          )}
          {isDropAndWins.length > 0 && (
            <img
              src={dnw}
              alt=""
              className="absolute top-0 left-0 w-16 z-1 md:w-20"
            />
          )}
          <div
            className={cx("w-full relative", {
              "aspect-w-2 aspect-h-1": size === "s2",
              "aspect-w-1 aspect-h-1": size === "s3" || size === "s4",
            })}
          >
            <img
              src={
                thumbnails[size].includes(".svg") || !cfImgUrl
                  ? thumbnails[size]
                  : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${thumbnails[size]}`
              }
              loading="lazy"
              className="absolute object-cover w-full"
            />
          </div>
          <div className="p-2.5">
            <p className="m-0 text-xs font-light text-gray">{game?.provider}</p>
            <p
              className={cx(
                "m-0 font-bold text-white text-16 ",
                isMobile.any ? " overflow-hidden whitespace-nowrap " : " "
              )}
              style={{ textOverflow: `ellipsis` }}
            >
              {game?.name}
            </p>
          </div>
        </Link>
      )}
    </ShowGame>
  );
};

export default GameItem;
